/*
 * Description of what this file is for
 *
 * @package  craft3
 * @author David Hellmann [david@hellmann.io]
 * select
 */

const radioGroup = {
  cfg: {
    radioGroupGroup: document.querySelectorAll('.a-radioGroup'),
  },

  checkValue(el, wrapper) {
    const _radioBtns = el.querySelectorAll('.a-radio--group');
    let _hasContent = false;

    for (let i = 0; i < _radioBtns.length; i += 1) {
      const _input = _radioBtns[i].querySelector('input');
      if (_input.checked) {
        _hasContent = true;
      }
    }

    if (_hasContent) {
      wrapper.classList.add('has-content');
    } else {
      wrapper.classList.remove('has-content');
    }
  },

  init() {
    for (let i = 0; i < this.cfg.radioGroupGroup.length; i += 1) {
      const _radios = this.cfg.radioGroupGroup[i].querySelector('.a-radioGroup__radios');

      this.checkValue(_radios, this.cfg.radioGroupGroup[i]);

      _radios.addEventListener('change', (el) => {
        el.currentTarget.classList.add('has-focused');
        this.checkValue(el.currentTarget, this.cfg.radioGroupGroup[i]);
      });
    }
  },
};

export default radioGroup;
