/*
 * Description of what this file is for
 *
 * @package  baukasten
 * @author Martin Hellwagner
 * modalForm
 */

import axios from 'axios';

const newsletterForm = {
  cfg: {
    name: 'newsletterForm',
    selectors: {
      newsletterForm: '.o-newsletterForm',
      formElement: '.o-newsletterForm form',
      input: '.a-input__input',
      inputContainer: '.a-input',
      checkbox: '.a-checkbox__checkbox',
      checkboxLabel: '.a-checkbox__label',
      submitButton: '.a-button',
    },
    el: {
      $newsletterForm: undefined,
      $formElement: undefined,
      $input: undefined,
      $checkbox: undefined,
      $submitButton: undefined,
    },
  },

  generateNewCsrfToken(form) {
    const csrfInput = form.querySelector('input[name="CRAFT_CSRF_TOKEN"]');
    if (csrfInput) {
      axios
        .get('/csrf')
        .then((response) => {
          [csrfInput.value] = response.data.split('<script');
        })
        .catch((error) => {
          // eslint-disable-next-line
                console.log(error);
        });
    }
  },
  init() {
    this.cfg.el.$newsletterForm = document.querySelector(this.cfg.selectors.newsletterForm);
    this.cfg.el.formElement = document.querySelector(this.cfg.selectors.formElement);

    if (this.cfg.el.$newsletterForm) {
      if (this.cfg.el.formElement) {
        this.generateNewCsrfToken(this.cfg.el.formElement);
      }

      this.cfg.el.$input = this.cfg.el.$newsletterForm.querySelector(this.cfg.selectors.input);
      this.cfg.el.$inputContainer = this.cfg.el.$newsletterForm.querySelector(
        this.cfg.selectors.inputContainer,
      );
      this.cfg.el.$checkbox = this.cfg.el.$newsletterForm.querySelector(
        this.cfg.selectors.checkbox,
      );
      this.cfg.el.$checkboxLabel = this.cfg.el.$newsletterForm.querySelector(
        this.cfg.selectors.checkboxLabel,
      );
      this.cfg.el.$submitButton = this.cfg.el.$newsletterForm.querySelector(
        this.cfg.selectors.submitButton,
      );

      // Remove errors
      this.cfg.el.$input.addEventListener('click', () => {
        this.cfg.el.$inputContainer.classList.remove('a-input--errors');
      });

      this.cfg.el.$checkbox.addEventListener('click', () => {
        this.cfg.el.$checkboxLabel.classList.remove('has-error');
      });

      // Handle validation
      this.cfg.el.$submitButton.addEventListener('click', (e) => {
        e.preventDefault();
        let errors = 0;

        // eslint-disable-next-line max-len
        if (
          (this.cfg.el.$input.hasAttribute('required') && this.cfg.el.$input.value === '') ||
          !this.cfg.el.$input.value.includes('@') ||
          !this.cfg.el.$input.value.includes('.')
        ) {
          this.cfg.el.$inputContainer.classList.add('a-input--errors');
          errors += 1;
        }

        if (this.cfg.el.$checkbox.hasAttribute('required') && !this.cfg.el.$checkbox.checked) {
          this.cfg.el.$checkboxLabel.classList.add('has-error');
          errors += 1;
        }

        // Submit form
        if (errors === 0) {
          this.cfg.el.$newsletterForm.querySelector('form').submit();
        }
      });
    }
  },
};

export default newsletterForm;
