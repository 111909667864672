// eslint-disable
import { computed } from 'vue';

export default function usePhoneNumber(number: string): string {
  const formattedNumber = computed<string>(() => {
    if (!number) {
      return '';
    }
    return number.replace('/s|-/g', '');
  });
  return formattedNumber.value;
}
