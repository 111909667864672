/**
 * Image Slider with Thumbnails
 */

import Flickity from 'flickity';
import 'flickity-as-nav-for';

const thumbnailSlider = {
  cfg: {
    slider: '.js-thumbnailSlider',
    sliderOptions: {
      cellAlign: 'left',
      contain: true,
      draggable: true,
      friction: 1,
      lazyLoad: 2,
      selectedAttraction: 0.1,
      groupCells: true,
      setGallerySize: true,
      imagesLoaded: true,
      prevNextButtons: false,
      pageDots: true,
      wrapAround: false,
      // watchCSS: true
    },
  },

  init() {
    window.addEventListener('load', () => {
      const sliders = [...document.querySelectorAll(this.cfg.slider)];
      if (sliders) {
        sliders.forEach((slider) => {
          const thumbnailNav = slider.parentNode.querySelector('.o-thumbnailSlider__nav');

          const flkty = new Flickity(slider, this.cfg.sliderOptions);
          flkty.resize();

          if (thumbnailNav) {
            const flktyNav = new Flickity(thumbnailNav, {
              asNavFor: slider,
              cellAlign: 'left',
              groupCells: true,
              prevNextButtons: false,
              pageDots: false,
              draggable: false,
            });
            flktyNav.resize();

            setTimeout(() => {
              flktyNav.resize();
            }, 1000);
          }

          setTimeout(() => {
            flkty.resize();
            slider.classList.add('is-ready');
          }, 1000);
        });
      }
    });
  },
};

export default thumbnailSlider;
