/*
 * @package  de.bistum-passau.www
 * @author Susanne Haberpointner [susi@fredmansky.at]
 * churchGuideSlider
 */
import Flickity from 'flickity';

const churchGuideSlider = {
  cfg: {
    slider: '.js-churchGuideSlider',
    sliderOptions: {
      cellAlign: 'center',
      contain: true,
      draggable: '>1',
      friction: 0.28,
      lazyLoad: 2,
      selectedAttraction: 0.025,
      groupCells: false,
      setGallerySize: true,
      imagesLoaded: false,
      prevNextButtons: false,
      pageDots: true,
      wrapAround: false,
      resize: true,
      dragTreshold: 10,
    },
  },

  init() {
    window.addEventListener('load', () => {
      const sliders = [...document.querySelectorAll(this.cfg.slider)];
      if (sliders) {
        sliders.forEach((slider) => {
          const flkty = new Flickity(slider, this.cfg.sliderOptions);
          flkty.resize();

          setTimeout(() => {
            flkty.resize();
            slider.classList.add('is-ready');
          }, 1000);

          // Add event listeners to disable horizontal dragging while scrolling vertically
          const slideContents = slider.querySelectorAll('.o-churchGuideSliderText__textWrapper');
          slideContents.forEach((content) => {
            content.addEventListener('scroll', function () {
              flkty.options.draggable = false;
              flkty.updateDraggable();

              clearTimeout(content.scrollTimeout);
              content.scrollTimeout = setTimeout(() => {
                flkty.options.draggable = true;
                flkty.updateDraggable();
              }, 750);
            });
          });
        });
      }
    });
  },
};

export default churchGuideSlider;
