/*
 * Description of what this file is for
 *
 * @package  de.bistum-passau.www
 * @author Theresa Obermayr [theresa@fredmansky.at]
 * productDetail
 */

const productDetail = {
  cfg: {
    name: 'productDetail',
    selectors: {
      productDetail: '.o-productDetail',
      addToCartButton: '.o-productDetail .a-button--productDetailAdd',
      inCartButton: '.o-productDetail .a-button--productDetailInCart',
      linkToCartButton: '.o-productDetail .a-link--productDetailLinkToCart',
      productId: '#domladenProductId',
      articleNr: '#domladenProductArtNr',
      productName: '#domladenProductName',
      productPrice: '#domladenProductPrice',
    },
    classes: {
      active: 'is-active',
      inActive: 'is-inActive',
      hidden: 'is-hidden',
      visible: 'is-visible',
    },
    el: {
      $productDetail: undefined,
      $addToCartButton: undefined,
      $inCartButton: undefined,
      $linkToCartButton: undefined,
      $productId: undefined,
      $articleNr: undefined,
      $productName: undefined,
      $productPrice: undefined,
    },
    els: {
      $productDetail: undefined,
    },
  },

  setElements() {
    this.cfg.el.$productDetail = document.querySelector(this.cfg.selectors.productDetail);
    this.cfg.el.$addToCartButton = document.querySelector(this.cfg.selectors.addToCartButton);
    this.cfg.el.$inCartButton = document.querySelector(this.cfg.selectors.inCartButton);
    this.cfg.el.$linkToCartButton = document.querySelector(this.cfg.selectors.linkToCartButton);
    this.cfg.el.$productId = document.querySelector(this.cfg.selectors.productId);
    this.cfg.el.$articleNr = document.querySelector(this.cfg.selectors.articleNr);
    this.cfg.el.$productName = document.querySelector(this.cfg.selectors.productName);
    this.cfg.el.$productPrice = document.querySelector(this.cfg.selectors.productPrice);
    if (this.cfg.el.$productId) {
      this.cfg.el.$productId = this.cfg.el.$productId.value;
    }
    if (this.cfg.el.$articleNr) {
      this.cfg.el.$articleNr = this.cfg.el.$articleNr.value;
    }
    if (this.cfg.el.$productName) {
      this.cfg.el.$productName = this.cfg.el.$productName.value;
    }
    if (this.cfg.el.$productPrice) {
      this.cfg.el.$productPrice = this.cfg.el.$productPrice.value;
    }
  },

  addEventListeners() {
    if (this.cfg.el.$addToCartButton && this.cfg.el.$productId) {
      const isDisabled = this.cfg.el.$addToCartButton.classList.contains('disabled');
      if (!isDisabled) {
        this.cfg.el.$addToCartButton.addEventListener('click', () => {
          this.updateCart(
            this.cfg.el.$articleNr,
            this.cfg.el.$productId,
            this.cfg.el.$productPrice,
            this.cfg.el.$productName,
          );
          this.cfg.el.$addToCartButton.classList.add('disabled');
          this.cfg.el.$addToCartButton.innerHTML = 'Bereits im Warenkorb';
          window.location.href = '/domladen/warenkorb?added=1';
        });
      }
    }
  },

  updateCart(articleNr, productId, productPrice, productName) {
    const cartItem = {
      productId: productId,
      articleNr: articleNr,
      productName: productName,
      amount: 1,
      price: productPrice,
    };

    const cart = window.localStorage.getItem('domChart');

    if (cart) {
      const cartArray = JSON.parse(window.localStorage.getItem('domChart'));
      cartArray.push(cartItem);
      window.localStorage.setItem('domChart', JSON.stringify(cartArray));
      this.updateHeaderNotification(false, cartArray.length);
    } else {
      const cartArray = [];
      cartArray.push(cartItem);
      window.localStorage.setItem('domChart', JSON.stringify(cartArray));
      this.updateHeaderNotification(true, 1);
    }
  },

  updateHeaderNotification(newCart, amount) {
    const headerElement = document.querySelector('#domladen__cartHeaderNotification');
    if (headerElement) {
      if (newCart) {
        headerElement.classList.remove('hidden');
      }

      const amountSpan = headerElement.querySelector('.domladen__cartHeaderNotificationAmount');
      if (amountSpan) {
        amountSpan.innerHTML = amount;
      }
    }
  },

  disabledIfInCart() {
    const isInCart = this.checkIfInCart();
    if (isInCart) {
      this.cfg.el.$addToCartButton.classList.add('disabled');
      this.cfg.el.$addToCartButton.classList.add('hidden');
      this.cfg.el.$inCartButton.classList.remove('hidden');
      this.cfg.el.$linkToCartButton.classList.remove('hidden');
      this.cfg.el.$addToCartButton.innerHTML = 'Im Warenkorb';
    }
  },

  checkIfInCart() {
    const cart = window.localStorage.getItem('domChart');
    if (cart && this.cfg.el.$productId) {
      const cartArray = JSON.parse(window.localStorage.getItem('domChart'));
      for (let i = 0; i < cartArray.length; i++) {
        if (cartArray[i].productId === this.cfg.el.$productId) {
          return true;
        }
      }
      return false;
    }
  },

  init() {
    this.setElements();
    this.disabledIfInCart();
    this.addEventListeners();
  },
};

export default productDetail;
