/*
 * @package  de.bistum-passau.www
 * @author Martin Hellwagner [martin@fredmansky.at]
 * image
 */

const image = {
  cfg: {
    name: 'image',
    classes: {
      visible: 'is-visible',
    },
    selectors: {
      iconInfo: '.a-svgIcon--single\\/iconInfo',
      sourceText: '.a-image__sourceText',
    },
    els: {
      $icons: undefined,
    },
  },

  init() {
    this.cfg.els.$icons = document.querySelectorAll(this.cfg.selectors.iconInfo);

    this.cfg.els.$icons.forEach((icon) => {
      icon.addEventListener('click', (e) => {
        const sourceText = e.target.parentNode.querySelector(this.cfg.selectors.sourceText);

        if (!sourceText) return;
        sourceText.classList.toggle(this.cfg.classes.visible);
      });
    });
  },
};

export default image;
