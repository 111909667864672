/**
 Embed
 */

const embed = {
  cfg: {
    name: 'embed',
    selectors: {
      embed: '.a-embed',
      placeholder: '.o-placeholderAudioVideoSingle',
    },
    els: {
      $embeds: undefined,
    },
    // @see https://docs.usercentrics.com/#/smart-data-protector
    requiredUCServices: {
      'youtube.com': 'BJz7qNsdj-7',
      'www.youtube.com': 'BJz7qNsdj-7',
      'vimeo.com': 'HyEX5Nidi-m',
      'player.vimeo.com': 'HyEX5Nidi-m',
      'w.soundcloud.com': 'HyP_q4sdobm',
    },
  },

  setElements() {
    this.cfg.els.$embeds = document.querySelectorAll(this.cfg.selectors.embed);
  },

  setConsentForEmbeds() {
    for (let i = 0; i < this.cfg.els.$embeds.length; i++) {
      this.setConsentForEmbed(this.cfg.els.$embeds[i]);
    }
  },

  setConsentForEmbed(element) {
    const placeHolder = element.nextElementSibling;
    const requirement = this.detectIframeRequirement(placeHolder);
    const cookieServices = window.UC_UI.getServicesBaseInfo();

    /**
     * This is a workaround for the case that the embed is not handled by UC.
     * In this case we need to hide the placeholder and show the embed.
     * @see https://fredmansky.atlassian.net/browse/BIPAW-1222
     */
    const isHandled = !!cookieServices.find((service) => service.id == requirement);

    const requiredCookiesActive = !!cookieServices.find(
      (service) => service.id == requirement && service.consent.status,
    );

    if (requiredCookiesActive || !isHandled) {
      // hide placeholder
      if (placeHolder && placeHolder.classList.contains('o-placeholderAudioVideoSingle')) {
        placeHolder.classList.add('hidden');
      }

      // set source of embed
      element.classList.remove('hidden');
      const video = element.querySelector('.cookieEmbed');

      if (video) {
        const source = video.getAttribute('embed-src');
        if (source) {
          if (video.classList.contains('embedLazy')) {
            video.classList.remove('embedLazy');
            video.setAttribute('data-src', source);
            video.classList.add('lazyload');
          } else {
            video.setAttribute('src', source);
          }
          video.classList.remove('cookieEmbed');
        }
      }
    }
  },

  // function to be called in cookie box script after consent is set
  renderEmbedsAfterCookieSubmit() {
    this.setElements();

    if (this.cfg.els.$embeds && this.cfg.els.$embeds.length > 0) {
      this.setConsentForEmbeds();
    }
  },

  detectIframeRequirement(parentElement) {
    const parser = new DOMParser();
    const parsed = parser.parseFromString(parentElement.dataset.code, 'text/html');
    const iframe = parsed.querySelector('iframe');

    if (parsed && iframe) {
      const service = new URL(iframe.src).hostname;
      return this.cfg.requiredUCServices[service];
    }
  },

  init() {
    this.setElements();

    if (this.cfg.els.$embeds && this.cfg.els.$embeds.length > 0) {
      window.addEventListener('UC_UI_INITIALIZED', () => {
        this.renderEmbedsAfterCookieSubmit();
      });
    }
  },
};

export default embed;
