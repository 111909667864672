/*
 * Description of what this file is for
 *
 * @package  de.bistum-passau.www
 * @author David Hellmann [david@fredmansky.at]
 * contentSlider
 */

import Flickity from 'flickity';

const contentSlider = {
  cfg: {
    name: 'contentSlider',
    selectors: {
      contentSlider: 'o-contentSlider',
    },
    classes: {
      active: 'is-active',
      inActive: 'is-inActive',
      hidden: 'is-hidden',
      visible: 'is-visible',
    },
    el: {
      $contentSlider: undefined,
    },
    els: {
      $contentSlider: undefined,
    },
    slider: '.js-contentSlider',
    sliderOptions: {
      cellAlign: 'left',
      contain: true,
      draggable: true,
      friction: 0.8,
      lazyLoad: 2,
      selectedAttraction: 0.05,
      groupCells: true,
      setGallerySize: true,
      imagesLoaded: true,
      prevNextButtons: false,
      pageDots: false,
      wrapAround: true,
      autoPlay: 12000,
      pauseAutoPlayOnHover: false,
    },
  },

  init() {
    window.addEventListener('load', () => {
      this.flktySliders = [];
      this.number = [];
      this.sliderDivs = [...document.querySelectorAll(this.cfg.slider)];
      this.initializeSlider();
      this.addEventListenersForNumbers();
      this.addChangeListener();
    });
  },

  initializeSlider() {
    if (this.sliderDivs) {
      this.sliderDivs.forEach((slider, sliderIndex) => {
        const flkty = new Flickity(slider, {
          ...this.cfg.sliderOptions,
          on: {
            ready: () => {
              this.changeCallback(null, sliderIndex, 0);
            },
          },
        });
        setTimeout(() => {
          slider.classList.add('is-ready');
          flkty.resize();
        }, 500);
        this.flktySliders.push(flkty);
      });
    }
  },

  addEventListenersForNumbers() {
    if (this.sliderDivs) {
      this.sliderDivs.forEach((sliderDiv, sliderIndex) => {
        sliderDiv.querySelectorAll('.js-numberCount').forEach((number) => {
          const {
            dataset: { slidenumber },
          } = number;
          number.addEventListener('click', () => {
            this.flktySliders[sliderIndex].select(slidenumber, false, false);
            this.flktySliders[sliderIndex].stopPlayer();
            this.removeAllActiveClasses(sliderIndex);
          });
        });
      });
    }
  },

  addChangeListener() {
    this.flktySliders.forEach((flkty, sliderIndex) => {
      flkty.on('change', (index) => {
        this.changeCallback(flkty, sliderIndex, index);
      });
    });
  },

  removeAllActiveClasses(sliderIndex) {
    this.sliderDivs[sliderIndex].querySelectorAll('.js-numberCount').forEach((number) => {
      number.classList.remove('a-numberCount--activeAnimation');
    });
  },

  changeCallback(flkty, sliderIndex, index) {
    this.sliderDivs[sliderIndex].querySelectorAll('.js-numberCount').forEach((number) => {
      number.classList.remove('a-numberCount--active');
      number.classList.remove('a-numberCount--activeAnimation');

      const {
        dataset: { slidenumber },
      } = number;
      if (index === Number(slidenumber)) {
        number.classList.add('a-numberCount--active');

        if (!flkty || flkty.player.state === 'playing') {
          // only if player is started
          number.classList.add('a-numberCount--activeAnimation');
        }
      }
    });
  },
};

export default contentSlider;
