/**
 * datepicker
 */

import flatpickr from 'flatpickr';
import { German } from 'flatpickr/dist/l10n/de';
import moment from 'moment';

const datepicker = {
  cfg: {
    inputGroup: undefined,
    disableMobile: true,
    selectors: {
      datePicker: '.a-datepicker',
      inputField: '.a-datepicker__input',
    },
    els: {
      $datePickers: undefined,
    },
  },

  setElements() {
    this.cfg.inputGroup = document.querySelectorAll('.a-datepicker');
    this.cfg.els.$datePickers = document.querySelectorAll(this.cfg.selectors.datePicker);
  },

  initDatePicker(elem) {
    if (!this.cfg.disableMobile) {
      elem.classList.add('mobile');
    }

    const inputField = elem.querySelector(this.cfg.selectors.inputField);
    if (inputField && !inputField._flatpickr) {
      const minDate = inputField.getAttribute('data-datepicker-min-date');
      const maxDate = inputField.getAttribute('data-datepicker-max-date');

      flatpickr(inputField, {
        dateFormat: 'd.m.Y',
        minDate: minDate,
        maxDate: maxDate,
        locale: German,
        allowInput: true,
        disableMobile: this.cfg.isMobile,
      });
    }
  },

  checkValue(el) {
    const _value = el.value;

    if (_value !== '') {
      el.classList.add('has-content');
    } else {
      el.classList.remove('has-content');
    }
  },

  /* eslint-disable */
    addWrapperClass(el) {
        const _parent = el.parentNode;

        let date = moment(el.value, "DD.MM.YYYY");

        if (
            ! el.classList.contains('has-content') ||
            el.validity.badInput === true ||
            el.validity.customError === true ||
            el.validity.patternMismatch === true ||
            el.validity.rangeOverflow === true ||
            el.validity.rangeUnderflow === true ||
            el.validity.stepMismatch === true ||
            el.validity.tooLong === true ||
            el.validity.tooShort === true ||
            el.validity.typeMismatch === true ||
            el.validity.valid === false ||
            el.validity.valueMissing === true ||
            date.isValid() === false
        ) {
            _parent.classList.remove('is-valid');
        } else {
            _parent.classList.add('is-valid');
        }
    },

    init() {
        /* eslint-enable */
    this.setElements();

    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      this.cfg.disableMobile = false;
    }

    if (this.cfg.els.$datePickers) {
      for (let i = 0; i < this.cfg.els.$datePickers.length; i++) {
        this.initDatePicker(this.cfg.els.$datePickers[i]);
      }
    }

    for (let i = 0; i < this.cfg.inputGroup.length; i += 1) {
      const _input = this.cfg.inputGroup[i].querySelector('.a-datepicker__input');

      this.checkValue(_input);

      if (_input.classList.contains('has-content')) {
        this.addWrapperClass(_input);
      }

      _input.addEventListener('keyup', (el) => {
        el.currentTarget.classList.add('has-focused');
        this.checkValue(el.currentTarget);
        this.addWrapperClass(el.currentTarget);
      });

      _input.addEventListener('change', (el) => {
        el.currentTarget.classList.add('has-focused');
        this.checkValue(el.currentTarget);
        this.addWrapperClass(el.currentTarget);
      });
    }
  },
};

export default datepicker;
