/*
 * Description of what this file is for
 *
 * @package  de.bistum-passau.www
 * @author Theresa Obermayr [theresa@fredmansky.at]
 * datepickerCalendar
 */

import flatpickr from 'flatpickr';
import { German } from 'flatpickr/dist/l10n/de';
import getAncestorWith from '../../jsHelper/getAncestorWith';

const datepickerCalendar = {
  cfg: {
    name: 'datepickerCalendar',
    selectors: {
      datePicker: '.o-datepickerCalendar',
      dateFiled: '.o-datepickerCalendar__input',
    },
    els: {
      $datePickers: undefined,
    },
  },

  setElements() {
    this.cfg.els.$datePickers = document.querySelectorAll(this.cfg.selectors.datePicker);
  },

  initDatePicker(elem) {
    const inputField = elem.querySelector(this.cfg.selectors.dateFiled);
    const form = getAncestorWith('form', inputField);
    let minDate = elem.getAttribute('data-min-date');
    if (minDate) {
      minDate = new Date(minDate);
    }
    let maxDate = elem.getAttribute('data-max-date');
    if (maxDate) {
      maxDate = new Date(maxDate);
    }

    if (inputField && form) {
      flatpickr(inputField, {
        minDate: minDate,
        maxDate: maxDate,
        dateFormat: 'd-m-Y',
        locale: German,
        inline: true,
        disableMobile: true,
      });

      inputField.addEventListener('change', () => {
        form.submit();
      });
    }
  },

  init() {
    this.setElements();

    if (this.cfg.els.$datePickers) {
      for (let i = 0; i < this.cfg.els.$datePickers.length; i++) {
        this.initDatePicker(this.cfg.els.$datePickers[i]);
      }
    }
  },
};

export default datepickerCalendar;
