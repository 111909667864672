/*
 * @package  de.bistum-passau.www
 * @author Martin Hellwagner [martin@fredmansky.at]
 * navMainSubSite
 */

import _debounce from 'lodash/debounce';

const navMainSubSite = {
  cfg: {
    name: 'navMainSubSite',
    selectors: {
      body: 'body',
      navMain: 'm-navMainSubSite',
      linksMain: '.m-navMainSubSite__listItem--level-1',
      linksSub: '.m-navMainSubSite__listItem--level-2',
      burgerIcon: '.m-navMainSubSite__burgerIcon',
      closeIcon: '.m-navMainSubSite__closeIcon',
      menuMain: '.m-navMainSubSite__list',
      submenu: '.m-navMainSubSite__submenu--level-2',
      subsubmenu: '.m-navMainSubSite__submenu--level-3',
    },
    classes: {
      active: 'is-active',
      visible: 'is-visible',
    },
    el: {
      $body: undefined,
      $navMain: undefined,
      $burgerIcon: undefined,
      $closeIcon: undefined,
      $searchIcon: undefined,
      $menuMain: undefined,
    },
    els: {
      $linkListMain: undefined,
      $linkListSub: undefined,
    },
  },

  setElements() {
    this.cfg.el.$body = document.querySelector(this.cfg.selectors.body);
    this.cfg.el.$navMain = document.querySelector(this.cfg.selectors.navMain);
    this.cfg.els.$linkListMain = document.querySelectorAll(this.cfg.selectors.linksMain);
    this.cfg.els.$linkListSub = document.querySelectorAll(this.cfg.selectors.linksSub);
    this.cfg.el.$burgerIcon = document.querySelector(this.cfg.selectors.burgerIcon);
    this.cfg.el.$closeIcon = document.querySelector(this.cfg.selectors.closeIcon);
    this.cfg.el.$searchIcon = document.querySelector(this.cfg.selectors.searchIcon);
    this.cfg.el.$searchCloseIcon = document.querySelector(this.cfg.selectors.searchCloseIcon);
    this.cfg.el.$searchBar = document.querySelector(this.cfg.selectors.searchBar);
    this.cfg.el.$menuMain = document.querySelector(this.cfg.selectors.menuMain);
  },

  addHoverEventListener() {
    if (this.cfg.els.$linkListMain) {
      this.cfg.els.$linkListMain.forEach((link) => {
        const windowWidth = document.documentElement.clientWidth;

        if (windowWidth >= 1024) {
          link.addEventListener('mouseenter', this.evtMouseEnter);
          link.addEventListener('mouseleave', this.evtMouseLeave);

          if ('ontouchstart' in window) {
            link.addEventListener('touchend', this.evtMouseClick);
          }
        } else if ('ontouchstart' in window) {
          link.addEventListener('touchstart', () => {
            this.cfg.touch = true;
          });
          link.addEventListener('touchmove', () => {
            this.cfg.touch = false;
          });
          link.addEventListener('touchend', this.evtFingerTouched);
        } else {
          link.addEventListener('click', this.evtMouseClick);
        }
      });
    }

    if (this.cfg.els.$linkListSub) {
      this.cfg.els.$linkListSub.forEach((link) => {
        const windowWidth = document.documentElement.clientWidth;

        if (windowWidth >= 1024) {
          link.addEventListener('mouseenter', this.evtMouseEnter);
          link.addEventListener('mouseleave', this.evtMouseLeave);

          if ('ontouchstart' in window) {
            link.addEventListener('touchend', this.evtMouseClick);
          }
        }
      });
    }
  },

  removeHoverEventListener() {
    if (this.cfg.els.$linkListMain) {
      this.cfg.els.$linkListMain.forEach((link) => {
        link.removeEventListener('mouseenter', this.evtMouseEnter);
        link.removeEventListener('mouseleave', this.evtMouseLeave);
        link.removeEventListener('click', this.evtMouseClick);
        link.removeEventListener('touchend', this.evtFingerTouched);
      });
    }

    if (this.cfg.els.$linkListSub) {
      this.cfg.els.$linkListSub.forEach((link) => {
        link.removeEventListener('mouseenter', this.evtMouseEnter);
        link.removeEventListener('mouseleave', this.evtMouseLeave);
        link.removeEventListener('click', this.evtMouseClick);
        link.removeEventListener('touchend', this.evtFingerTouched);
      });
    }
  },

  evtMouseEnter(evt) {
    evt.target.classList.add(this.cfg.classes.active);

    if (evt.target.classList.contains(this.cfg.selectors.linksMain.substring(1))) {
      const submenu = evt.target.querySelector(this.cfg.selectors.submenu);
      if (submenu) {
        submenu.classList.add(this.cfg.classes.visible);
      }
    } else if (evt.target.querySelector(this.cfg.selectors.subsubmenu)) {
      const subsubmenu = evt.target.querySelector(this.cfg.selectors.subsubmenu);
      if (subsubmenu) {
        subsubmenu.classList.add(this.cfg.classes.visible);
      }
    }
  },

  evtMouseLeave(evt) {
    setTimeout(() => {
      evt.target.classList.remove(this.cfg.classes.active);

      if (evt.target.classList.contains(this.cfg.selectors.linksMain.substring(1))) {
        const submenu = evt.target.querySelector(this.cfg.selectors.submenu);
        if (submenu) {
          submenu.classList.remove(this.cfg.classes.visible);
        }
      } else if (evt.target.querySelector(this.cfg.selectors.subsubmenu)) {
        const subsubmenu = evt.target.querySelector(this.cfg.selectors.subsubmenu);
        if (subsubmenu) {
          subsubmenu.classList.remove(this.cfg.classes.visible);
        }
      }
    }, 125);
  },

  evtMouseClick(evt) {
    evt.target.classList.toggle(this.cfg.classes.active);

    if (evt.target.classList.contains(this.cfg.selectors.linksMain.substring(1))) {
      const submenu = evt.target.querySelector(this.cfg.selectors.submenu);
      if (submenu) {
        submenu.classList.toggle(this.cfg.classes.visible);
      }
    } else if (evt.target.querySelector(this.cfg.selectors.subsubmenu)) {
      const subsubmenu = evt.target.querySelector(this.cfg.selectors.subsubmenu);
      if (subsubmenu) {
        subsubmenu.classList.toggle(this.cfg.classes.visible);
      }
    }
  },

  evtFingerTouched(evt) {
    if (this.cfg.touch) {
      evt.target.classList.toggle(this.cfg.classes.active);

      if (evt.target.classList.contains(this.cfg.selectors.linksMain.substring(1))) {
        const submenu = evt.target.querySelector(this.cfg.selectors.submenu);
        if (submenu) {
          submenu.classList.toggle(this.cfg.classes.visible);
        }
      } else if (evt.target.querySelector(this.cfg.selectors.subsubmenu)) {
        const subsubmenu = evt.target.querySelector(this.cfg.selectors.subsubmenu);
        if (subsubmenu) {
          subsubmenu.classList.toggle(this.cfg.classes.visible);
        }
      }
    }
  },

  addEventListenerForMobileMenu() {
    this.cfg.el.$burgerIcon?.addEventListener('click', () => {
      this.cfg.el.$burgerIcon.classList.remove(this.cfg.classes.visible);
      this.cfg.el.$closeIcon.classList.add(this.cfg.classes.visible);
      this.cfg.el.$menuMain.classList.add(this.cfg.classes.visible);

      if (this.cfg.el.$body) {
        this.cfg.el.$body.classList.add('mobileNavOpen');
      }
    });
    this.cfg.el.$closeIcon?.addEventListener('click', () => {
      this.cfg.el.$burgerIcon.classList.add(this.cfg.classes.visible);
      this.cfg.el.$closeIcon.classList.remove(this.cfg.classes.visible);
      this.cfg.el.$menuMain.classList.remove(this.cfg.classes.visible);

      if (this.cfg.el.$body) {
        this.cfg.el.$body.classList.remove('mobileNavOpen');
      }
    });
  },

  resize() {
    this.removeHoverEventListener();
    this.addHoverEventListener();
  },

  checkIfCartActive() {
    const cart = window.localStorage.getItem('domChart');

    if (cart) {
      const cartArray = JSON.parse(window.localStorage.getItem('domChart'));

      if (cartArray.length > 0) {
        const headerElement = document.querySelector('#domladen__cartHeaderNotification');
        if (headerElement) {
          headerElement.classList.remove('hidden');
          const amountSpan = headerElement.querySelector('.domladen__cartHeaderNotificationAmount');
          if (amountSpan) {
            amountSpan.innerHTML = cartArray.length;
          }
        }
      }
    }
  },

  init() {
    this.evtMouseEnter = this.evtMouseEnter.bind(this);
    this.evtMouseLeave = this.evtMouseLeave.bind(this);
    this.evtMouseClick = this.evtMouseClick.bind(this);
    this.evtFingerTouched = this.evtFingerTouched.bind(this);

    window.addEventListener('load', () => {
      this.setElements();
      this.addHoverEventListener();
      this.addEventListenerForMobileMenu();
      this.checkIfCartActive();
    });

    window.addEventListener('resize', _debounce(this.resize.bind(this), 250));
  },
};

export default navMainSubSite;
