/*
 * Description of what this file is for
 *
 * @package  de.bistum-passau.www
 * @author David Hellmann [david@fredmansky.at]
 * introSection
 */

import MicroModal from 'micromodal';

const introSection = {
  cfg: {
    name: 'introSection',
    modalId: '',
    selectors: {
      appContent: '.o-appContent',
      modalOverlay: '.o-modalForm__overlay',
      modalContainer: '.o-modalForm__container',
      modalOpenTrigger: '.a-button--js-modalOpenTrigger',
      modalCloseTriggerIcon: '.o-modalForm__iconClose',
      modalCloseTriggerText: '.a-plainText--js-modalCloseTrigger',
    },
    el: {
      $appContent: undefined,
      $modalOverlay: undefined,
      $modalContainer: undefined,
      $modalOpenTrigger: undefined,
      $modalCloseTriggerIcon: undefined,
      $modalCloseTriggerText: undefined,
    },
  },

  showModal(modal) {
    this.cfg.el.$appContent.appendChild(document.getElementById(modal.id));
    const modalOverlay = modal.querySelector(this.cfg.selectors.modalOverlay);
    modalOverlay?.classList.add('is-open--modal');

    // Disable scrolling on html and body
    document.documentElement.style.position = 'relative';
    document.documentElement.style.height = '100%';
    document.documentElement.style.overflow = 'hidden';
    document.body.style.position = 'relative';
    document.body.style.height = '100%';
    document.body.style.overflow = 'hidden';
  },

  closeModal(modal) {
    const modalOverlay = modal.querySelector(this.cfg.selectors.modalOverlay);
    modalOverlay?.classList.remove('is-open--modal');

    // Disable scrolling on html and body
    document.documentElement.style.position = '';
    document.documentElement.style.height = '';
    document.documentElement.style.overflow = '';
    document.body.style.position = '';
    document.body.style.height = '';
    document.body.style.overflow = '';
  },

  init() {
    this.cfg.el.$appContent = document.querySelector(this.cfg.selectors.appContent);
    this.cfg.el.$modalContainers = document.querySelectorAll(this.cfg.selectors.modalContainer);
    this.cfg.el.$modalOpenTriggers = document.querySelectorAll(this.cfg.selectors.modalOpenTrigger);

    if (this.cfg.el.$modalContainers) {
      this.cfg.el.$modalContainers.forEach((container) => {
        const closeTriggerIcon = container.querySelector(this.cfg.selectors.modalCloseTriggerIcon,);
        const closeTriggerText = container.querySelector(this.cfg.selectors.modalCloseTriggerText,);

        closeTriggerIcon?.addEventListener('click', () => {
          MicroModal.close(this.cfg.modalId);
        });
        closeTriggerText?.addEventListener('click', () => {
          MicroModal.close(this.cfg.modalId);
        });
      });

      // Open modal
      if (this.cfg.el.$modalOpenTriggers) {
        this.cfg.el.$modalOpenTriggers.forEach((trigger) => {
          trigger.addEventListener('click', (evt) => {
            const modalId = `o-modalForm${
              trigger.dataset.modalId ? `-${trigger.dataset.modalId}` : ''
            }`;

            MicroModal.show(modalId, {
              onShow: (modal) => this.showModal(modal),
              onClose: (modal) => this.closeModal(modal),
              disableScroll: true,
            });
          });
        });
      }
    }
  },
};

export default introSection;
