/*
 * @package  de.bistum-passau.www
 * @author Susanne Haberpointner [susi@fredmansky.at]
 * video
 */

const churchGuideSliderVideo = {
  cfg: {
    name: 'churchGuideSliderVideo',
    selectors: {
      videos: '.o-churchGuideSliderVideo',
      videoThumbnails: '.o-churchGuideSliderVideo .a-videoThumbnail' /* Video Thumbnail */,
      videoPlaceholders: '.o-churchGuideSlider .o-placeholderAudioVideoMultiple',
      modal: '#videoModal',
      videoContainer: '#videoContainer',
    },
    classes: {
      hidden: 'is-hidden',
      playing: 'playing',
    },
    el: {
      $videos: undefined,
      $videoThumbnails: undefined,
      $videoPlaceholders: undefined,
      $modal: undefined,
      $videoContainer: undefined,
    },
    player: undefined,
    requiredUCServices: [
      'BJz7qNsdj-7', // YOUTUBE
      'HyEX5Nidi-m', // VIMEO
    ],
  },

  setElements() {
    this.cfg.el.$videoThumbnails = Array.from(
      document.querySelectorAll(this.cfg.selectors.videoThumbnails),
    );
    this.cfg.el.$modal = document.querySelector(this.cfg.selectors.modal);
    this.cfg.el.$videoPlaceholders = Array.from(
      document.querySelectorAll(this.cfg.selectors.videoPlaceholders),
    );
    this.cfg.el.$videos = Array.from(document.querySelectorAll(this.cfg.selectors.videos));
    this.cfg.el.$videoContainer = document.querySelector(this.cfg.selectors.videoContainer);
  },

  addEventListeners() {
    this.cfg.el.$videoThumbnails.forEach(($thumbnail) => {
      $thumbnail.addEventListener('click', () => {
        const videoId = $thumbnail.closest('.o-churchGuideSliderVideo').getAttribute('data-id');
        const platform = $thumbnail.closest('.o-churchGuideSliderVideo').getAttribute('data-type');
        this.openModal(videoId, platform);
      });
    });

    if (this.cfg.el.$modal) {
      const closeButton = this.cfg.el.$modal.querySelector('.close-modal');
      closeButton.addEventListener('click', () => this.closeModal());
    }
  },

  openModal(videoId, platform) {
    this.cfg.el.$videoContainer.innerHTML = '';
    this.initializePlayer(platform, videoId, this.cfg.el.$videoContainer.id);
    this.cfg.el.$modal.classList.remove(this.cfg.classes.hidden);
  },

  closeModal() {
    if (this.cfg.player) {
      if (this.cfg.player.stopVideo) {
        this.cfg.player.stopVideo();
      } else {
        this.cfg.player.unload();
      }
      this.cfg.player.destroy();
      this.cfg.player = null;
    }
    this.cfg.el.$modal.classList.add(this.cfg.classes.hidden);
  },

  loadYoutube(videoId, containerId) {
    window.YT.ready(() => {
      this.cfg.player = new YT.Player(containerId, {
        videoId: videoId,
        host: 'https://www.youtube-nocookie.com',
        playerVars: {
          autoplay: 1,
          fs: 0,
          color: 'white',
          modestbranding: 1,
          showinfo: 0,
          rel: 0,
          disablekb: 1,
          playsinline: 1,
        },
        events: {
          onReady: this.onPlayerReady,
        },
      });
    });
  },

  loadVimeo(videoId) {
    const iframe = document.createElement('iframe');
    iframe.src = `https://player.vimeo.com/video/${videoId}?rel=0`;
    iframe.allow = 'autoplay';
    this.cfg.el.$videoContainer.appendChild(iframe);
    this.cfg.player = new Vimeo.Player(iframe);
    this.cfg.player.play();
  },

  initializePlayer(platform, videoId, containerId) {
    if (platform === 'youtube') {
      if (typeof YT === 'undefined') {
        this.loadExternalScript('https://www.youtube.com/iframe_api', () => {
          this.loadYoutube(videoId, containerId);
        });
      } else {
        this.loadYoutube(videoId, containerId);
      }
    } else if (platform === 'vimeo') {
      if (typeof Vimeo === 'undefined') {
        this.loadExternalScript('https://player.vimeo.com/api/player.js', () => {
          this.loadVimeo(videoId);
        });
      } else {
        this.loadVimeo(videoId);
      }
    }
  },

  setConsentForSection() {
    if (this.cfg.el.$videoPlaceholders.length > 0) {
      this.cfg.el.$videoPlaceholders.forEach(($placeholder) => {
        $placeholder.classList.add(this.cfg.classes.hidden);
      });
    }
    if (this.cfg.el.$videos.length > 0) {
      this.cfg.el.$videos.forEach(($video) => {
        $video.classList.remove(this.cfg.classes.hidden);
      });
    }
  },

  onPlayerReady(event) {
    event.target.playVideo();
  },

  // function to be called in cookie box script after consent is set
  renderVideoSectionAfterCookieSubmit() {
    this.initVideoSection();
  },

  loadExternalScript(src, callback) {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    document.head.appendChild(script);
    script.onload = () => {
      console.log(`${src} loaded successfully.`);
      callback();
    };
    script.onerror = () => {
      console.error(`Error loading script: ${src}`);
    };
  },

  initVideoSection() {
    this.setElements();
    if (this.cfg.el.$videos) {
      const cookieServices = window.UC_UI.getServicesBaseInfo();
      const videoCookiesActivated = cookieServices.filter(
        (service) => this.cfg.requiredUCServices.includes(service.id) && service.consent.status,
      );
      const requiredServicesConfirmed =
        videoCookiesActivated.length === this.cfg.requiredUCServices.length;

      console.log(requiredServicesConfirmed);

      // CHECK FOR YOUTUBE & VIMEO COOKIES
      if (requiredServicesConfirmed) {
        this.setConsentForSection();
        this.addEventListeners();
      } else {
        if (this.cfg.el.$videoPlaceholders.length > 0) {
          this.cfg.el.$videoPlaceholders.forEach(($placeholder) => {
            $placeholder.classList.remove('!hidden');
          });
        }
        if (this.cfg.el.$videos.length > 0) {
          this.cfg.el.$videos.forEach(($video) => {
            $video.classList.add(this.cfg.classes.hidden);
          });
        }
      }
    }
  },

  init() {
    window.addEventListener('UC_UI_INITIALIZED', () => {
      this.initVideoSection();
    });

    document.addEventListener('gesturestart', function (e) {
      e.preventDefault();
      document.body.style.zoom = 0.99;
    });

    document.addEventListener('gesturechange', function (e) {
      e.preventDefault();

      document.body.style.zoom = 0.99;
    });
    document.addEventListener('gestureend', function (e) {
      e.preventDefault();
      document.body.style.zoom = 1;
    });
  },
};
export default churchGuideSliderVideo;
