/*
 * Description of what this file is for
 *
 * @package  de.bistum-passau.www
 * @author David Hellmann [david@fredmansky.at]
 * chatBanner
 */

const chatBanner = {
  cfg: {
    name: 'chatBanner',
    selectors: {
      chatBanner: '.js-chatBanner--close',
    },
    el: {
      $chatBanner: undefined,
    },
  },

  setElements() {
    this.cfg.el.$chatBanner = document.querySelector(this.cfg.selectors.chatBanner);
  },

  init() {
    this.setElements();
    if (this.cfg.el.$chatBanner) {
      this.cfg.el.$chatBanner.addEventListener('click', (el) => {
        // TODO: Check if usercentrics is necessary here!
        el.currentTarget.parentElement.parentElement.remove();
      });
    }
  },
};

export default chatBanner;
