/*
 * Description of what this file is for
 *
 * @package  de.bistum-passau.www
 * @author David Hellmann [david@fredmansky.at]
 * bellDetail
 */

const bellDetail = {
  // Config
  cfg: {
    body: document.body,
    triggers: [...document.querySelectorAll('.js-bellDetailTrigger')],
    status: 'is-open',
    bellPropertiesSelector: '.o-bellDetail__properties',
  },

  // Toggle Content
  toggleContent(event) {
    event.preventDefault();
    const target = event.currentTarget.parentElement;
    const accContent = target.nextElementSibling;
    const accWrapper = target.parentElement;
    if (target && accContent) {
      if (!target.classList.contains(this.cfg.status)) {
        target.classList.add(this.cfg.status);
        accWrapper.classList.add(this.cfg.status);
        accContent.style.display = 'block';
      } else {
        target.classList.remove(this.cfg.status);
        accWrapper.classList.remove(this.cfg.status);
        accContent.style.display = 'none';
      }
    }
  },

  hideEmptyFields() {
    const bellProps = document.querySelectorAll(this.cfg.bellPropertiesSelector);
    for (let i = 0; i < bellProps.length; i++) {
      if (
        bellProps[i].childNodes.length < 2 &&
        this.closestByClass(bellProps[i], 'o-bellDetail__dividerAndProps')
      ) {
        this.closestByClass(bellProps[i], 'o-bellDetail__dividerAndProps').classList.add('isEmpty');
      }
    }
  },

  hideEmptyDetailLinks() {
    const bellDetailElem = document.querySelectorAll('.o-bellDetail');
    if (bellDetailElem) {
      for (let i = 0; i < bellDetailElem.length; i++) {
        const notEmptyChildren = bellDetailElem[i].querySelectorAll(
          '.o-bellDetail__dividerAndProps:not(.isEmpty)',
        );
        if (notEmptyChildren.length === 0) {
          bellDetailElem[i].classList.add('isEmpty');
        }
      }
    }
  },

  closestByClass(el, clazz) {
    // Traverse the DOM up with a while loop
    while (!el.classList.contains(clazz)) {
      // Increment the loop to the parent node
      el = el.parentNode;
      if (!el) {
        return null;
      }
    }
    return el;
  },

  init() {
    this.hideEmptyFields();
    this.hideEmptyDetailLinks();

    this.cfg.triggers.forEach((trigger) => {
      trigger.addEventListener('click', (event) => {
        this.toggleContent(event);
      });
    });
  },
};

export default bellDetail;
