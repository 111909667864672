/*
 * @package  de.bistum-passau.www
 * @author Julia Kaupert
 * placeholderAudioVideoSingle
 */

const placeholderAudioVideoSingle = {
  cfg: {
    name: 'placeholderAudioVideoSingle',
    selectors: {
      submitBtn: '.o-placeholderAudioVideoSingle__submitButton',
    },
    els: {
      $submitBtns: undefined,
    },
  },

  setElements() {
    this.cfg.els.$submitBtns = document.querySelectorAll(this.cfg.selectors.submitBtn);
  },

  setAudioVideoCookie() {
    // Triggers the cookie consent layer provided by UserCentrics
    if (window.UC_UI) {
      window.UC_UI.showSecondLayer();
    }
  },

  init() {
    this.setElements();
    if (this.cfg.els.$submitBtns && this.cfg.els.$submitBtns.length > 0) {
      for (let i = 0; i < this.cfg.els.$submitBtns.length; i++) {
        this.cfg.els.$submitBtns[i].addEventListener('click', () => {
          this.setAudioVideoCookie();
        });
      }
    }
  },
};

export default placeholderAudioVideoSingle;
