/*
 * Description of what this file is for
 *
 * @package  de.bistum-passau.www
 * @author David Hellmann [david@fredmansky.at]
 * focusTopic
 */

const focusTopic = {
  cfg: {
    name: 'focusTopic',
    selectors: {
      focusTopic: 'o-focusTopic',
    },
    classes: {
      visible: 'is-visible',
      active: 'is-active',
    },
    el: {
      $focusTopic: undefined,
      $topicNavSelect: undefined,
    },
    els: {
      $topicNavLinks: undefined,
      $topicTeaserDivs: undefined,
      $topicImages: undefined,
    },
    activeIndex: 0,
  },

  setElements() {
    this.cfg.el.$focusTopic = document.querySelector(this.cfg.selectors.focusTopic);
    this.cfg.els.$topicNavLinks = document.querySelectorAll(
      `.${this.cfg.selectors.focusTopic}__navigation ul li`,
    );
    this.cfg.el.$topicNavSelect = document.querySelector(
      `.${this.cfg.selectors.focusTopic}__navigation select`,
    );
    this.cfg.els.$topicTeaserDivs = document.querySelectorAll(
      `.${this.cfg.selectors.focusTopic}__teaser`,
    );
    this.cfg.els.$topicImages = document.querySelectorAll(
      `.${this.cfg.selectors.focusTopic}__image`,
    );
  },

  activateFirstTopic() {
    if (this.cfg.els.$topicNavLinks[0]) {
      this.cfg.els.$topicNavLinks[0].classList.add(this.cfg.classes.active);
      this.cfg.els.$topicTeaserDivs[0].classList.add(this.cfg.classes.visible);
      this.cfg.els.$topicImages[0].classList.add(this.cfg.classes.visible);
    }
  },

  removeActiveStateForIndex(index) {
    this.cfg.els.$topicNavLinks[index].classList.remove(this.cfg.classes.active);
    this.cfg.els.$topicTeaserDivs[index].classList.remove(this.cfg.classes.visible);
    this.cfg.els.$topicImages[index].classList.remove(this.cfg.classes.visible);
  },

  addActiveStateForIndex(index) {
    this.cfg.els.$topicNavLinks[index].classList.add(this.cfg.classes.active);
    this.cfg.els.$topicTeaserDivs[index].classList.add(this.cfg.classes.visible);
    this.cfg.els.$topicImages[index].classList.add(this.cfg.classes.visible);
  },

  switchContentToIndex(index) {
    if (index !== this.cfg.activeIndex) {
      this.removeActiveStateForIndex(this.cfg.activeIndex);
      this.addActiveStateForIndex(index);
      this.cfg.activeIndex = index;
    }
  },

  addEventListeners() {
    this.cfg.els.$topicNavLinks.forEach((link, index) => {
      link.querySelector('a').addEventListener('click', () => {
        this.switchContentToIndex(index);
      });
    });

    if (this.cfg.el.$topicNavSelect) {
      this.cfg.el.$topicNavSelect.addEventListener('change', (evt) => {
        this.switchContentToIndex(evt.target.value);
      });
    }
  },

  init() {
    window.addEventListener('load', () => {
      this.setElements();
      this.activateFirstTopic();
      this.addEventListeners();
    });
  },
};

export default focusTopic;
