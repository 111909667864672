/*
 * Description of what this file is for
 *
 * @package  craft3
 * @author David Hellmann [david@hellmann.io]
 * select
 */

const select = {
  cfg: {
    selectGroup: document.querySelectorAll('.a-select'),
  },

  checkValue(el, wrapper) {
    const _value = el.value;

    if (_value !== '' && _value !== null) {
      el.classList.add('has-content');
      wrapper.classList.add('has-content');
    } else {
      el.classList.remove('has-content');
      wrapper.classList.remove('has-content');
    }
  },

  /* eslint-disable */
    addWrapperClass(el) {
        const _parent = el.parentNode;

        if (
            ! el.classList.contains('has-content') ||
            el.validity.badInput === true ||
            el.validity.customError === true ||
            el.validity.patternMismatch === true ||
            el.validity.rangeOverflow === true ||
            el.validity.rangeUnderflow === true ||
            el.validity.stepMismatch === true ||
            el.validity.tooLong === true ||
            el.validity.tooShort === true ||
            el.validity.typeMismatch === true ||
            el.validity.valid === false ||
            el.validity.valueMissing === true
        ) {
            _parent.classList.remove('is-valid');
        } else {
            _parent.classList.add('is-valid');
        }
    },
    /* eslint-enable */

  init() {
    for (let i = 0; i < this.cfg.selectGroup.length; i += 1) {
      const _select = this.cfg.selectGroup[i].querySelector('.a-select__select');

      this.checkValue(_select, this.cfg.selectGroup[i]);

      if (_select.classList.contains('has-content')) {
        this.addWrapperClass(_select);
      }

      _select.addEventListener('keyup', (el) => {
        el.currentTarget.classList.add('has-focused');
        this.checkValue(el.currentTarget, this.cfg.selectGroup[i]);
        this.addWrapperClass(el.currentTarget);
      });

      _select.addEventListener('change', (el) => {
        el.currentTarget.classList.add('has-focused');
        this.checkValue(el.currentTarget, this.cfg.selectGroup[i]);
        this.addWrapperClass(el.currentTarget);
      });
    }
  },
};

export default select;
