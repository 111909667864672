import scrollSpy from 'simple-scrollspy'

const routingTeaser = {
  init() {
    const mediumScreenMin = 800
    const windowInnerWitdh = window.innerWidth
    const bodyWidth = document.getElementsByTagName('body')[0].clientWidth
    const currentScreen = windowInnerWitdh || bodyWidth;

    if (currentScreen >= mediumScreenMin ) {
      scrollSpy('#aside', {
        sectionClass: '.scrollspy',           // Query selector to your sections
        menuActiveTarget: '.menu-item',       // Query selector to your elements that will be added `active` class
      })
    }
  }
}

export default routingTeaser;
