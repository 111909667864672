import { computed } from 'vue';

export default function useRootClass({ cn = '', modifiers = [] }) {
  const rootClass = computed(() => {
    const _modifiers = modifiers.map((mod) => `${cn}--${mod}`).join(' ');

    return [cn, _modifiers];
  });

  return { rootClass };
}
