/*
 * @package  de.bistum-passau.www
 * @author Martin Hellwagner [martin@fredmansky.at]
 * navMainSubSite
 */

import _debounce from 'lodash/debounce';

const navMain = {
  cfg: {
    name: 'navMain',
    selectors: {
      body: 'body',
      navMain: 'm-navMain',
      linksMain: '.m-navMain__listItem--level-1',
      linksSub: '.m-navMain__list--level-2',
      linksSubMobile: '.m-navMain__list--level-2--mobile',
      burgerIcon: '.m-navMain__burgerIcon',
      closeIcon: '.m-navMain__closeIcon',
      menuMain: '.m-navMain__list',
    },

    classes: {
      active: 'is-active',
      visible: 'is-visible',
      visibleSub: 'is-visible-sub',
    },

    el: {
      $body: undefined,
      $navMain: undefined,
      $burgerIcon: undefined,
      $closeIcon: undefined,
      $searchIcon: undefined,
      $menuMain: undefined,
    },

    els: {
      $linkListMain: undefined,
      $linkListSub: undefined,
      $linkListSubMobile: undefined,
    },
  },

  setElements() {
    this.cfg.el.$body = document.querySelector(this.cfg.selectors.body);
    this.cfg.el.$navMain = document.querySelector(this.cfg.selectors.navMain);
    this.cfg.els.$linkListMain = document.querySelectorAll(this.cfg.selectors.linksMain);
    this.cfg.els.$linkListSub = document.querySelectorAll(this.cfg.selectors.linksSub);
    this.cfg.els.$linkListSubMobile = document.querySelectorAll(this.cfg.selectors.linkListSubMobile);
    this.cfg.el.$burgerIcon = document.querySelector(this.cfg.selectors.burgerIcon);
    this.cfg.el.$closeIcon = document.querySelector(this.cfg.selectors.closeIcon);
    this.cfg.el.$searchIcon = document.querySelector(this.cfg.selectors.searchIcon);
    this.cfg.el.$searchCloseIcon = document.querySelector(this.cfg.selectors.searchCloseIcon);
    this.cfg.el.$searchBar = document.querySelector(this.cfg.selectors.searchBar);
    this.cfg.el.$menuMain = document.querySelector(this.cfg.selectors.menuMain);
  },

  addHoverEventListener() {
    if (this.cfg.els.$linkListMain) {
      this.cfg.els.$linkListMain.forEach(link => {
        const windowWidth = document.documentElement.clientWidth;

        if (windowWidth >= 1024) {
          link.addEventListener('mouseenter', this.evtMouseEnter);
          link.addEventListener('mouseleave', this.evtMouseLeave);

          if ('ontouchstart' in window) {
            link.addEventListener('touchend', this.evtMouseClick);
          }
        } else if ('ontouchstart' in window) {
          link.addEventListener('touchstart', () => {
            this.cfg.touch = true;
          });
          link.addEventListener('touchmove', () => {
            this.cfg.touch = false;
          });
          link.addEventListener('touchend', this.evtFingerTouched);
        } else {
          link.addEventListener('click', this.evtMouseClick);
        }
      });
    }

    if (this.cfg.els.$linkListSub) {
      this.cfg.els.$linkListSub.forEach(link => {
        const windowWidth = document.documentElement.clientWidth;

        if (windowWidth >= 1024) {
          link.addEventListener('mouseenter', this.evtMouseEnter);
          link.addEventListener('mouseleave', this.evtMouseLeave);

          if ('ontouchstart' in window) {
            link.addEventListener('touchend', this.evtMouseClick);
          }
        }
      });
    }
  },

  removeHoverEventListener() {
    if (this.cfg.els.$linkListMain) {
      this.cfg.els.$linkListMain.forEach((link) => {
        link.removeEventListener('mouseenter', this.evtMouseEnter);
        link.removeEventListener('mouseleave', this.evtMouseLeave);
        link.removeEventListener('click', this.evtMouseClick);
        link.removeEventListener('touchend', this.evtFingerTouched);
      });
    }

    if (this.cfg.els.$linkListSub) {
      this.cfg.els.$linkListSub.forEach((link) => {
        link.removeEventListener('mouseenter', this.evtMouseEnter);
        link.removeEventListener('mouseleave', this.evtMouseLeave);
      });
    }
  },

  evtMouseEnter(evt) {
    // Main Navigation
    if (evt.target.classList.contains(this.cfg.selectors.linksMain.substring(1))) {
      evt.target.classList.add(this.cfg.classes.active);

      const subNav = document.querySelector(`#${evt.target.dataset.id}`);
      if (subNav) {
        subNav.classList.add(this.cfg.classes.visible);
      }
    // Sub Navigation
    } else if (evt.target.classList.contains(this.cfg.selectors.linksSub.substring(1))) {
      evt.target.classList.add(this.cfg.classes.visibleSub);

      const mainNav = document.querySelector(`[data-id="${evt.target.id}"]`);
      if (mainNav) {
        mainNav.classList.add(this.cfg.classes.active);
      }
    }
  },

  evtMouseLeave(evt) {
    // Main Navigation
    if (evt.target.classList.contains(this.cfg.selectors.linksMain.substring(1))) {
      evt.target.classList.remove(this.cfg.classes.active);

      const subNav = document.querySelector(`#${evt.target.dataset.id}`);
      if (subNav) {
        subNav.classList.remove(this.cfg.classes.visible);
      }
    // Sub Navigation
    } else if (evt.target.classList.contains(this.cfg.selectors.linksSub.substring(1))) {
      setTimeout(() => {
        evt.target.classList.remove(this.cfg.classes.visibleSub);

        const mainNav = document.querySelector(`[data-id="${evt.target.id}"]`);
        if (mainNav) {
          mainNav.classList.remove(this.cfg.classes.active);
        }
      }, 125);
    }
  },

  evtMouseClick(evt) {
    // Main Navigation
    if (evt.target.classList.contains(this.cfg.selectors.linksMain.substring(1))) {
      evt.target.classList.toggle(this.cfg.classes.active);

      const subNav = document.querySelector(`#${evt.target.dataset.id}`);
      if (subNav) {
        subNav.classList.toggle(this.cfg.classes.visible);
      }
    // Sub Navigation
    } else if (evt.target.classList.contains(this.cfg.selectors.linksSub.substring(1))) {
      evt.target.classList.toggle(this.cfg.classes.visibleSub);

      const mainNav = document.querySelector(`[data-id="${evt.target.id}"]`);
      if (mainNav) {
        mainNav.classList.toggle(this.cfg.classes.active);
      }
    }
  },

  evtFingerTouched(evt) {
    if (this.cfg.touch) {
      evt.target.classList.toggle(this.cfg.classes.active);

      const submenu = evt.target.querySelector(this.cfg.selectors.linksSubMobile);
      if (submenu) {
        submenu.classList.toggle(this.cfg.classes.visible);
      }
    }
  },

  addEventListenerForMobileMenu() {
    this.cfg.el.$burgerIcon?.addEventListener('click', () => {
      this.cfg.el.$burgerIcon.classList.remove(this.cfg.classes.visible);
      this.cfg.el.$closeIcon.classList.add(this.cfg.classes.visible);
      this.cfg.el.$menuMain.classList.add(this.cfg.classes.visible);

      if (this.cfg.el.$body) {
        this.cfg.el.$body.classList.add('mobileNavOpen');
      }
    });

    this.cfg.el.$closeIcon?.addEventListener('click', () => {
      this.cfg.el.$burgerIcon.classList.add(this.cfg.classes.visible);
      this.cfg.el.$closeIcon.classList.remove(this.cfg.classes.visible);
      this.cfg.el.$menuMain.classList.remove(this.cfg.classes.visible);

      if (this.cfg.el.$body) {
        this.cfg.el.$body.classList.remove('mobileNavOpen');
      }
    });
  },

  resize() {
    this.removeHoverEventListener();
    this.addHoverEventListener();
  },

  checkIfCartActive() {
    const cart = window.localStorage.getItem('domChart');

    if (cart) {
      const cartArray = JSON.parse(window.localStorage.getItem('domChart'));

      if (cartArray.length > 0) {
        const headerElement = document.querySelector('#domladen__cartHeaderNotification');
        if (headerElement) {
          headerElement.classList.remove('hidden');
          const amountSpan = headerElement.querySelector('.domladen__cartHeaderNotificationAmount');
          if (amountSpan) {
            amountSpan.innerHTML = cartArray.length;
          }
        }
      }
    }
  },

  init() {
    this.evtMouseEnter = this.evtMouseEnter.bind(this);
    this.evtMouseLeave = this.evtMouseLeave.bind(this);
    this.evtMouseClick = this.evtMouseClick.bind(this);
    this.evtFingerTouched = this.evtFingerTouched.bind(this);

    window.addEventListener('load', () => {
      this.setElements();
      this.addHoverEventListener();
      this.addEventListenerForMobileMenu();
      this.checkIfCartActive();
    });

    window.addEventListener('resize', _debounce(this.resize.bind(this), 250));
  },
};

export default navMain;
