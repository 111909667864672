/**
 * Scripts
 */

import bodyClass from '../scripts/bodyclass.js';
import browserDetect from '../scripts/bowser.js';
import loadScriptsAfterConsent from '../scripts/loadScriptsAfterConsent.js';
import setNavActiveState from '../scripts/setNavActiveState.js';
import '../scripts/lazyloading.js';
import '../scripts/addUCEvents.js';

// Modules Array
const scriptsArray = [bodyClass, browserDetect, loadScriptsAfterConsent, setNavActiveState];

const scripts = {
  // Scripts
  Scripts() {
    if (scriptsArray.length > 0) {
      scriptsArray.forEach((script) => {
        script.init();
      });
    }
  },

  init() {
    this.Scripts();
  },
};

export default scripts;
