/**
 * loadScriptsAfterConsent
 */

const loadScriptsAfterConsent = {
  loadgoogletagmanager() {
    const gTag = document.querySelector('#gtagScript');
    if (gTag) {
      const source = gTag.getAttribute('consent-src');
      if (source) {
        gTag.setAttribute('src', source);
      }

      if (!window.dataLayer) {
        this.loadGa();
      }
    }
  },

  /* eslint-disable */
  loadGa() {
    const allCookies = document.cookie;
    if (allCookies.includes('cookieTracking=true')) {
      window.dataLayer = window.dataLayer || [];

      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'UA-122876124-1', {
        send_page_view: true,
        anonymize_ip: true,
        link_attribution: false,
        allow_display_features: false,
      });
    }
  },
  /* eslint-enable */

  loadBistumsblattScripts() {
    if (window.location.hostname.indexOf('passauerbistumsblatt') !== -1) {
      const bbScripts = document.querySelectorAll('.bistumsblattBannerScript');
      if (bbScripts && bbScripts.length > 0) {
        for (let i = 0; i < bbScripts.length; i++) {
          const source = bbScripts[i].getAttribute('consent-src');
          if (source) {
            bbScripts[i].setAttribute('src', source);
          }
        }
      }
    }
  },

  loadScriptsAfterCookieSubmit() {
    if (window.location.hostname.indexOf('temp.') === -1) {
      const allCookies = document.cookie;
      if (allCookies.includes('cookieTracking=true')) {
        this.loadgoogletagmanager();
        this.loadBistumsblattScripts();
      }
    }
  },

  init() {
    if (window.location.hostname.indexOf('temp.') === -1) {
      window.addEventListener('load', () => {
        const allCookies = document.cookie;
        if (allCookies.includes('cookieTracking=true')) {
          this.loadgoogletagmanager();
          this.loadBistumsblattScripts();
        }
      });
    }
  },
};

export default loadScriptsAfterConsent;
