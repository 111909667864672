/*
 * Description of what this file is for
 *
 * @package  de.bistum-passau.www
 * @author Theresa Obermayr [theresa@fredmansky.at]
 * datepicker
 */

import flatpickr from 'flatpickr';
import { German } from 'flatpickr/dist/l10n/de';

const datepicker = {
  cfg: {
    name: 'datepicker',
    disableMobile: true,
    selectors: {
      datePicker: '.o-datepicker',
      dateFiled: '.o-datepicker__input',
      cross: '.o-datepicker__cross',
    },
    els: {
      $datePickers: undefined,
    },
  },

  setElements() {
    this.cfg.els.$datePickers = document.querySelectorAll(this.cfg.selectors.datePicker);
  },

  checkValue(el) {
    const _value = el.value;

    if (_value !== '' && _value !== null) {
      el.classList.add('has-content');
    } else {
      el.classList.remove('has-content');
    }
  },

  initDatePicker(elem) {
    if (!this.cfg.disableMobile) {
      elem.classList.add('mobile');
    }

    const inputField = elem.querySelector(this.cfg.selectors.dateFiled);
    const cross = elem.querySelector(this.cfg.selectors.cross);

    flatpickr(inputField, {
      minDate: Date.now(),
      dateFormat: 'd.m.Y',
      locale: German,
      disableMobile: this.cfg.isMobile,
    });

    this.checkValue(inputField);

    inputField.addEventListener('change', (el) => {
      this.checkValue(el.currentTarget);
    });

    cross.addEventListener('click', () => {
      if (inputField._flatpickr) {
        inputField._flatpickr.clear();
      }
    });
  },

  init() {
    this.setElements();

    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      this.cfg.disableMobile = false;
    }

    if (this.cfg.els.$datePickers) {
      for (let i = 0; i < this.cfg.els.$datePickers.length; i++) {
        this.initDatePicker(this.cfg.els.$datePickers[i]);
      }
    }
  },
};

export default datepicker;
