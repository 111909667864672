/*
 * Description of what this file is for
 *
 * @package  de.bistum-passau.www
 * @author David Hellmann [david@fredmansky.at]
 * bellSearch
 */

import { createApp } from 'vue';
import BellSearchComponent from './_component.vue';

const bellSearch = {
  cfg: {
    name: 'bellSearch',
    selectors: {
      bellSearch: 'm-bellSearch',
    },
    classes: {
      active: 'is-active',
      inActive: 'is-inActive',
      hidden: 'is-hidden',
      visible: 'is-visible',
    },
    el: {
      $bellSearch: undefined,
    },
    els: {
      $bellSearch: undefined,
    },
  },

  init() {
    const vueWrapper = document.querySelector('#bellSearchVue');
    if (vueWrapper) {
      const app = createApp(BellSearchComponent).mount('#bellSearchVue');
    }
  },
};

export default bellSearch;
