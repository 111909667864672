import _debounce from 'lodash/debounce';

const toggleContent = {
  cfg: {
    classes: {
      isOpen: 'is-open',
    },
    selectors: {
      toggles: '.js-toggle',
    },
    els: {
      $toggles: null,
    },
  },

  resetOpenState(els) {
    els.forEach((toggle) => {
      toggle.classList.remove(this.cfg.classes.isOpen);
    });
  },

  addClickListener(els) {
    els.forEach((toggle) => {
      toggle.addEventListener('click', () => {
        toggle.classList.toggle(this.cfg.classes.isOpen);
      });
    });
  },

  setElements() {
    this.cfg.els.$toggles = [...document.querySelectorAll(this.cfg.selectors.toggles)];
  },

  init() {
    this.setElements();

    if (this.cfg.els.$toggles) {
      this.addClickListener(this.cfg.els.$toggles);
      window.addEventListener(
        'resize',
        _debounce(() => {
          this.resetOpenState(this.cfg.els.$toggles);
        }, 50),
      );
    }
  },
};

export default toggleContent;
