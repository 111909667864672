/*
 * Description of what this file is for
 *
 * @package  craft3
 * @author David Hellmann [david@hellmann.io]
 * select
 */

const checkboxGroup = {
  cfg: {
    checkboxGroupGroup: document.querySelectorAll('.a-checkboxGroup'),
  },

  checkValue(el, wrapper) {
    const _checkboxes = el.querySelectorAll('.a-checkbox--group');
    let _hasContent = false;

    for (let i = 0; i < _checkboxes.length; i += 1) {
      const _input = _checkboxes[i].querySelector('input');
      if (_input.checked) {
        _hasContent = true;
      }
    }

    if (_hasContent) {
      wrapper.classList.add('has-content');
    } else {
      wrapper.classList.remove('has-content');
    }
  },

  init() {
    for (let i = 0; i < this.cfg.checkboxGroupGroup.length; i += 1) {
      const _checkboxes = this.cfg.checkboxGroupGroup[i].querySelector(
        '.a-checkboxGroup__checkboxes',
      );

      this.checkValue(_checkboxes, this.cfg.checkboxGroupGroup[i]);

      _checkboxes.addEventListener('change', (el) => {
        el.currentTarget.classList.add('has-focused');
        this.checkValue(el.currentTarget, this.cfg.checkboxGroupGroup[i]);
      });
    }
  },
};

export default checkboxGroup;
