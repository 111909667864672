/*
 * Description of what this file is for
 *
 * @package  de.bistum-passau.www
 * @author Theresa Obermayr [theresa@fredmansky.at]
 * eventSitebar
 */

const eventSitebar = {
  checkValue(el) {
    const _value = el.value;

    if (_value !== '' && _value !== null) {
      el.classList.add('has-content');
    } else {
      el.classList.remove('has-content');
    }
  },

  initDatePickerEventListeners() {
    let dateFromPicker = null;
    let dateToPicker = null;
    const dateFrom = document.querySelector('input[name="startDate"]');
    const dateTo = document.querySelector('input[name="endDate"]');

    if (dateFrom) {
      dateFromPicker = dateFrom._flatpickr;
    }
    if (dateTo) {
      dateToPicker = dateTo._flatpickr;
    }

    if (dateFromPicker && dateToPicker) {
      dateFromPicker.config.onChange.push(() => {
        dateToPicker.config.minDate = dateFromPicker.latestSelectedDateObj;
        this.checkValue(dateTo);
      });
    }
  },

  initMobileFilterAccordion() {
    const trigger = document.querySelector('.o-eventSitebar__filterAccordionMobileTrigger');
    const sidebar = document.querySelector('.e-twoColumnsSticky__sidebar');

    if (trigger && sidebar) {
      trigger.addEventListener('click', () => {
        trigger.classList.toggle('is-open');
        sidebar.classList.toggle('is-open');
      });
    }
  },

  init() {
    this.initDatePickerEventListeners();
    this.initMobileFilterAccordion();
  },
};

export default eventSitebar;
