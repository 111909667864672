/*
 * @package  de.bistum-passau.www
 * @author Theresa Obermayr [theresa@fredmansky.at]
 * placeholderAudioVideoMultiple
 */

const placeholderAudioVideoMultiple = {
  cfg: {
    name: 'placeholderAudioVideoMultiple',
    selectors: {
      submitBtn: '.o-placeholderAudioVideoMultiple__submitButton',
    },
    el: {
      $submitBtn: undefined,
    },
  },

  setElements() {
    this.cfg.el$submitBtn = document.querySelector(this.cfg.selectors.submitBtn);
  },

  setAudioVideoCookie() {
    if (window.UC_UI) {
      window.UC_UI.showSecondLayer();
    }
  },

  init() {
    this.setElements();
    if (this.cfg.el$submitBtn) {
      this.cfg.el$submitBtn.addEventListener('click', () => {
        this.setAudioVideoCookie();
      });
    }
  },
};

export default placeholderAudioVideoMultiple;
