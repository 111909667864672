/*
 * @package  de.bistum-passau.www
 * @author Susanne Haberpointner [susi@fredmansky.at]
 * churchGuideInfoText
 */

const churchGuideInfoText = {
  cfg: {
    name: 'churchGuideInfoText',
    selectors: {
      showIcon: '.o-churchGuideInfoText .show-icon',
      closeIcon: '.o-churchGuideInfoText .close-icon',
      infoText: '.o-churchGuideInfoText__wrapper',
    },
    classes: {
      visible: 'is-visible',
      notransition: 'transition-none',
    },
    el: {
      $showIcon: undefined,
      $closeIcon: undefined,
      $infoText: undefined,
    },
  },
  setElements() {
    this.cfg.el.$showIcon = document.querySelector(this.cfg.selectors.showIcon);
    this.cfg.el.$closeIcon = document.querySelector(this.cfg.selectors.closeIcon);
    this.cfg.el.$infoText = document.querySelector(this.cfg.selectors.infoText);
  },

  addEventListeners() {
    if (this.cfg.el.$showIcon) {
      this.cfg.el.$showIcon.addEventListener('click', (e) => {
        e.preventDefault();
        this.cfg.el.$showIcon.classList.remove(this.cfg.classes.visible);
        this.cfg.el.$infoText.classList.add(this.cfg.classes.visible);
      });
    }

    if (this.cfg.el.$closeIcon) {
      this.cfg.el.$closeIcon.addEventListener('click', (e) => {
        e.preventDefault();
        this.cfg.el.$showIcon.classList.add(this.cfg.classes.visible);
        this.cfg.el.$infoText.classList.remove(this.cfg.classes.visible);
      });
    }
  },

  init() {
    this.setElements();
    this.addEventListeners();
  },
};

export default churchGuideInfoText;
