/**
 * setNavActiveState
 */

const setNavActiveState = {
  setMainNav() {
    const activeLink = document.querySelector(
      `.m-navMain__listItem [href="${window.location.href}"]`,
    );
    if (activeLink) {
      const parentNav = this.closestByClass(activeLink, 'm-navMain__listItem--level-1');

      if (parentNav) {
        const activeSpan = parentNav.querySelector('.navActiveIndicator');
        if (activeSpan) {
          activeSpan.classList.add('is-active');
        }
      }
    }
  },

  closestByClass(el, clazz) {
    // Traverse the DOM up with a while loop
    while (!el.classList.contains(clazz)) {
      // Increment the loop to the parent node
      el = el.parentNode;
      if (!el) {
        return null;
      }
    }
    return el;
  },

  setMetaNav() {
    const activeLink = document.querySelector(
      `.m-navFooterMeta__listItem [href="${window.location.href}"]`,
    );
    if (activeLink) {
      activeLink.classList.add('is-active');
    }
  },

  init() {
    window.addEventListener('load', () => {
      this.setMainNav();
      this.setMetaNav();
    });
  },
};

export default setNavActiveState;
