/**
 * Cover Flow Slider
 */

import Swiper, { EffectCoverflow, Navigation } from 'swiper';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';

const coverFlowSlider = {
  cfg: {
    slider: '.o-coverFlow__container',
    sliderOptions: {
      init: false,
      grabCursor: true,
      slidesPerView: 1,
      initialSlide: 0,
      loop: true,
      centeredSlides: true,
      effect: 'coverflow',
      breakpoints: {
        500: {
          slidesPerView: 2,
          initialSlide: 1,
          coverflowEffect: {
            modifier: 2.3,
          },
        },
        800: {
          slidesPerView: 3,
          initialSlide: 2,
          coverflowEffect: {
            modifier: 1.6,
          },
        },
      },
      navigation: {
        nextEl: null,
        nextElSelector: '.o-coverFlow__next',
        prevEl: null,
        prevElSelector: '.o-coverFlow__prev',
      },
      coverflowEffect: {
        rotate: 0,
        depth: 250,
        modifier: 1,
        slideShadows: false,
      },
    },
  },

  init() {
    window.addEventListener('load', () => {
      const sliders = [...document.querySelectorAll(this.cfg.slider)];
      if (sliders) {
        sliders.forEach((slider) => {
          this.cfg.sliderOptions.navigation.nextEl = slider.querySelector(
            this.cfg.sliderOptions.navigation.nextElSelector,
          );
          this.cfg.sliderOptions.navigation.prevEl = slider.querySelector(
            this.cfg.sliderOptions.navigation.prevElSelector,
          );

          const swiper = new Swiper(slider, {
            modules: [EffectCoverflow, Navigation],
            ...this.cfg.sliderOptions,
          });

          swiper.init();

          const controls = slider.parentNode.querySelector('.o-coverFlow__navigation');

          if (controls) {
            controls
              .querySelector(this.cfg.sliderOptions.navigation.nextElSelector)
              .addEventListener('click', () => {
                swiper.slideNext();
              });

            controls
              .querySelector(this.cfg.sliderOptions.navigation.prevElSelector)
              .addEventListener('click', () => {
                swiper.slidePrev();
              });
          }
        });
      }
    });
  },
};

export default coverFlowSlider;
