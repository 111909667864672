/*
 * Description of what this file is for
 *
 * @package  de.bistum-passau.www
 * @author David Hellmann [david@fredmansky.at]
 * search
 */

import { createApp } from 'vue';
import SearchComponent from './_component.vue';

const search = {
  cfg: {
    name: 'search',
    selectors: {
      search: 'm-search',
    },
    classes: {
      active: 'is-active',
      inActive: 'is-inActive',
      hidden: 'is-hidden',
      visible: 'is-visible',
    },
    el: {
      $search: undefined,
    },
    els: {
      $search: undefined,
    },
  },

  setElements() {
    this.cfg.el.$search = document.querySelector(this.cfg.selectors.search);
  },

  init() {
    const app = createApp(SearchComponent);
    app.mount('#vue');
  },
};

export default search;
