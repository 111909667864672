/**
 * Lazy Loading
 */

// Dependencies
import 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';

document.addEventListener('lazyloaded', (e) => {
  const target = e.target.parentNode;
  if (target) {
    target.style.backgroundColor = 'transparent';
  }
});

// Settings
window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.expand = 500; // default 360-500
window.lazySizesConfig.expFactor = 1.7; // default: 1.7
