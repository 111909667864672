/*
 * Description of what this file is for
 *
 * @package  de.bistum-passau.www
 * @author David Hellmann [david@fredmansky.at]
 * audioHtmlPlayer
 */

const audioHtmlPlayer = {
  cfg: {
    name: 'audioHtmlPlayer',
    selectors: {
      audioHtmlPlayer: '.a-audioHtmlPlayer',
      player: '.a-audioHtmlPlayer__player',
      playBtn: '.a-audioHtmlPlayer__playBtn',
      pauseBtn: '.a-audioHtmlPlayer__pauseBtn',
    },
    classes: {
      active: 'is-active',
      inActive: 'is-inActive',
      hidden: 'is-hidden',
      visible: 'is-visible',
    },
    el: {
      $audioHtmlPlayer: undefined,
    },
    els: {
      $audioHtmlPlayer: undefined,
    },
  },

  setElements() {
    this.cfg.els.$audioHtmlPlayer = document.querySelectorAll(this.cfg.selectors.audioHtmlPlayer);

    if (this.cfg.els.$audioHtmlPlayer) {
      for (let i = 0; i < this.cfg.els.$audioHtmlPlayer.length; i++) {
        const player = this.cfg.els.$audioHtmlPlayer[i].querySelector(this.cfg.selectors.player);
        const playButton = this.cfg.els.$audioHtmlPlayer[i].querySelector(
          this.cfg.selectors.playBtn,
        );
        const pauseButton = this.cfg.els.$audioHtmlPlayer[i].querySelector(
          this.cfg.selectors.pauseBtn,
        );
        if (player) {
          playButton.addEventListener('click', (event) => {
            event.preventDefault();
            player.play();
            pauseButton.classList.remove('hidden');
            playButton.classList.add('hidden');
          });

          pauseButton.addEventListener('click', (event) => {
            event.preventDefault();
            player.pause();
            player.currentTime = 0;
            playButton.classList.remove('hidden');
            pauseButton.classList.add('hidden');
          });
        }
      }
    }
  },

  init() {
    this.setElements();
  },
};

export default audioHtmlPlayer;
