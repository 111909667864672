/*
 * Description of what this file is for
 *
 * @package  de.bistum-passau.www
 * @author David Hellmann [david@fredmansky.at]
 * pollItem
 */

import { createApp } from 'vue';
import PollComponent from './_component.vue';

const pollItem = {
  cfg: {
    name: 'pollItem',
    selectors: {
      pollItem: 'm-pollItem',
    },
    classes: {
      active: 'is-active',
      inActive: 'is-inActive',
      hidden: 'is-hidden',
      visible: 'is-visible',
    },
  },

  init() {
    const vuePollElem = [...document.querySelectorAll('.vuePoll')];
    if (vuePollElem) {
      vuePollElem.forEach((vuePoll, index) => {
        const app = createApp(PollComponent);
        app.provide('pollItem', JSON.parse(vuePoll.nextElementSibling.getAttribute('data-poll')));
        app.mount(vuePoll);
      });
    }
  },
};

export default pollItem;
