// Import our CSS
import '../scss/app.scss';

// Polyfills
import 'babel-polyfill';
import 'svgxuse';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import objectFitImages from 'object-fit-images';

// Import Partials
import scripts from './partials/scripts';
import modules from './partials/modules';

const app = {
  init() {
    scripts.init();
    modules.init();
    objectFitImages();
  },
};

// Init App!
app.init();
