/**
 * modules
 */

import audioHtmlPlayer from '../../../templates/_atoms/audioHtmlPlayer/_script';
import preloader from '../../../templates/_atoms/preloader/_script';
import bellDetail from '../../../templates/_organisms/bellDetail/_script';
import mBellSearch from '../../../templates/_molecules/bellSearch/_script';
import checkboxGroup from '../../../templates/_atoms/checkboxGroup/_script';
import churchServicesSidebar from '../../../templates/_organisms/churchServicesSidebar/_script';
import chatBanner from '../../../templates/_molecules/chatBanner/_script';
import codeSnippet from '../../../templates/_atoms/codeSnippet/_script';
import datepickerFreeform from '../../../templates/_atoms/datepickerFreeform/_script';
import datepicker from '../../../templates/_organisms/datepicker/_script';
import datepickerCalendar from '../../../templates/_organisms/datepickerCalendar/_script';
import embed from '../../../templates/_atoms/embed/_script';
import contentSlider from '../../../templates/_organisms/contentSlider/_script';
import imageSlider from '../../../templates/_organisms/imageSlider/_script';
import thumbnailSlider from '../../../templates/_organisms/thumbnailSlider/_script';
import imageGallery from '../../../templates/_organisms/imageGallery/_script';
import linkSlider from '../../../templates/_organisms/linkSlider/_script';
import input from '../../../templates/_atoms/input/_script';
import textarea from '../../../templates/_atoms/textarea/_script';
import accordion from '../../../templates/_organisms/accordion/_script';
import gridOverlay from '../../../templates/_molecules/gridOverlay/_script';
import navMainSubSite from '../../../templates/_molecules/navMain/_scriptSubSite';
import navMain from '../../../templates/_molecules/navMain/_script';
import navSlider from '../../../templates/_molecules/navSlider/_script';
import focusTopic from '../../../templates/_organisms/focusTopic/_script';
import image from '../../../templates/_atoms/image/_script';
import search from '../../../templates/_molecules/search/_script';
import searchChurch from '../../../templates/_organisms/searchChurches/_script';
import select from '../../../templates/_atoms/select/_script';
import comments from '../../../templates/_organisms/comments/_script';
import introSection from '../../../templates/_organisms/introSection/_script';
import modalForm from '../../../templates/_organisms/modalForm/_script';
import newsletterForm from '../../../templates/_organisms/newsletterForm/_script';
import videoSection from '../../../templates/_organisms/videoSection/_script';
import pollItem from '../../../templates/_molecules/pollItem/_script';
import productDetail from '../../../templates/_organisms/productDetail/_script';
import freeform from '../../../templates/_organisms/freeform/_script';
import radioGroup from '../../../templates/_atoms/radioGroup/_script';
import toggleContent from '../../../templates/_atoms/toggle/_script';
import eventSitebar from '../../../templates/_organisms/eventSitebar/_script';
import navFooterMeta from '../../../templates/_molecules/navFooterMeta/_script';
import placeholderAudioVideoSingle from '../../../templates/_organisms/placeholderAudioVideoSingle/_script';
import placeholderAudioVideoMultiple from '../../../templates/_organisms/placeholderAudioVideoMultiple/_script';
import coverFlow from '../../../templates/_organisms/coverFlow/_script';
import routingTeaser from '../../../templates/_organisms/routingTeaser/_script';
import churchServicesEmptySearch from '../../../templates/_organisms/churchServicesEmptySearch/_script';
import churchGuideSlider from '../../../templates/_organisms/churchGuideSlider/_script';
import churchGuideSliderVideo from '../../../templates/_molecules/churchGuideSliderVideo/_script';
import churchGuideInfoText from '../../../templates/_organisms/churchGuideInfoText/_script';

// Modules Array
const modulesArray = [
  audioHtmlPlayer,
  preloader,
  bellDetail,
  mBellSearch,
  chatBanner,
  checkboxGroup,
  churchServicesSidebar,
  codeSnippet,
  datepickerFreeform,
  datepicker,
  datepickerCalendar,
  embed,
  contentSlider,
  imageSlider,
  thumbnailSlider,
  imageGallery,
  input,
  linkSlider,
  textarea,
  accordion,
  gridOverlay,
  navMainSubSite,
  navMain,
  navSlider,
  focusTopic,
  search,
  select,
  image,
  toggleContent,
  comments,
  introSection,
  modalForm,
  newsletterForm,
  videoSection,
  pollItem,
  productDetail,
  freeform,
  radioGroup,
  eventSitebar,
  navFooterMeta,
  placeholderAudioVideoSingle,
  placeholderAudioVideoMultiple,
  coverFlow,
  routingTeaser,
  searchChurch,
  churchServicesEmptySearch,
  churchGuideSlider,
  churchGuideSliderVideo,
  churchGuideInfoText,
];

const modules = {
  // Modules
  Modules() {
    if (modulesArray.length > 0) {
      modulesArray.forEach((module) => {
        module.init();
      });
    }
  },

  init() {
    this.Modules();
  },
};

export default modules;
